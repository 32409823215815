import type { Route } from 'nextjs-routes';

import config from 'configs/app';

const TEMPLATE_MAP: Record<Route['pathname'], string> = {
  '/': '%network_name% blockchain explorer',
  '/txs': '%network_name% transactions',
  '/txs/kettle/[hash]': 'kettle %hash% transactions',
  '/tx/[hash]': 'transaction %hash%',
  '/blocks': 'blocks',
  '/block/[height_or_hash]': 'block %height_or_hash%',
  '/accounts': 'top accounts',
  '/address/[hash]': 'address details for %hash%',
  '/verified-contracts': 'Verified contracts lookup',
  '/contract-verification': 'verify contract',
  '/address/[hash]/contract-verification': 'contract verification for %hash%',
  '/tokens': 'Tokens list',
  '/token/[hash]': 'token details',
  '/token/[hash]/instance/[id]': 'NFT instance',
  '/apps': 'DApps',
  '/apps/[id]': 'marketplace app',
  '/stats': 'stats - network insights',
  '/api-docs': 'API docs - developer tools',
  '/graphiql': 'GraphQL for - data query',
  '/search-results': 'search result for %q%',
  '/auth/profile': 'my profile',
  '/account/watchlist': 'watchlist',
  '/account/api-key': 'API keys',
  '/account/custom-abi': 'custom ABI',
  '/account/tag-address': 'private tags',
  '/account/verified-addresses': 'my verified addresses',
  '/public-tags/submit': 'public tag requests',
  '/withdrawals': 'withdrawals - track on',
  '/visualize/sol2uml': 'Solidity UML diagram',
  '/csv-export': 'export data to CSV',
  '/deposits': 'deposits (L1 > L2)',
  '/output-roots': 'output roots',
  '/dispute-games': 'dispute games',
  '/batches': 'tx batches (L2 blocks)',
  '/batches/[number]': 'L2 tx batch %number%',
  '/blobs/[hash]': 'blob %hash% details',
  '/ops': 'User operations on',
  '/op/[hash]': 'user operation %hash%',
  '/404': 'error - page not found',
  '/name-domains': 'name domains',
  '/name-domains/[name]': '%name% domain details',
  '/validators': 'validators list',
  '/gas-tracker': 'gas tracker - Current gas fees',

  // service routes, added only to make typescript happy
  '/login': 'login',
  '/sprite': 'SVG sprite',
  '/api/metrics': 'node API prometheus metrics',
  '/api/log': 'node API request log',
  '/api/media-type': 'node API media type',
  '/api/proxy': 'node API proxy',
  '/api/csrf': 'node API CSRF token',
  '/api/healthz': 'node API health check',
  '/api/config': 'node API app config',
  '/api/sprite': 'node API SVG sprite content',
  '/auth/auth0': 'authentication',
  '/auth/unverified-email': 'unverified email',
};

const TEMPLATE_MAP_ENHANCED: Partial<Record<Route['pathname'], string>> = {
  '/token/[hash]': '%symbol% token details',
  '/token/[hash]/instance/[id]': 'token instance for %symbol%',
  '/apps/[id]': '%app_name%',
  '/address/[hash]': 'address details for %domain_name%',
};

export function make(pathname: Route['pathname'], isEnriched = false) {
  const template = (isEnriched ? TEMPLATE_MAP_ENHANCED[pathname] : undefined) ?? TEMPLATE_MAP[pathname];
  const postfix = config.meta.promoteBlockscoutInTitle ? ' | Blockscout' : '';

  return (template + postfix).trim();
}
